<template>
  <div class="page page__content-wrapper">
    <div class="page__min-container">
      <div class="page__text-block">
        <div class="text__head">
          Написание в тексте
        </div>
        <div class="text__normal">
          В русских и англоязычных документах название пишется одинаково: прописными латинскими буквами, без пробелов и
          точек между ними. Не допускается транслитерировать аббревиатуру.
        </div>
      </div>

    </div>
    <div class="page__min-container page__content-block">
      <div class="page__two-block">
        <CommunicationDialog v-bind:dialog="dialogEvent"/>
      </div>
      <div class="page__two-block right">
        <CommunicationDialog v-bind:dialog="dialogBrand"/>
      </div>
    </div>
    <div class="page__min-container ">
      <div class="page__text-block">
        <div class="text__head">
          Юридические документы
        </div>
        <div class="text__normal">
          Написание ООО «Джи Ди Пи» используется исключительно в юридических документах.
        </div>
        <div class="text__normal">
          В крайнем случае, при отсутствии возможности использовать название GDP, допускается написание «Джи Ди Пи».
          Однако такое написание нежелательно.
        </div>
        <div class="text__normal">
          Внутри наборного текста допускается использование small caps:
        </div>
      </div>

    </div>
    <div class="page__min-container page__content-block">
      <div class="page__one-block">
        <CommunicationDialog v-bind:dialog="dialogJuristic"/>
      </div>
    </div>
    <div class="page__min-container ">
      <div class="page__text-block">
        <div class="text__head">
          Падеж
        </div>
        <div class="text__normal">
          Если из контекста не до конца ясен падеж, то перед названием добавляется слово «компания».
        </div>
      </div>

    </div>
    <div class="page__min-container page__content-block">
      <div class="page__two-block">
        <CommunicationDialog v-bind:dialog="dialogCase"/>
      </div>
      <div class="page__two-block">
        <CommunicationDialog v-bind:dialog="dialogCaseFalse"/>
      </div>
    </div>
    <div class="page__min-container ">
      <div class="page__text-block">
        <div class="text__head">
          Аудио
        </div>
        <div class="text__normal">
          В устном произношении название бренда звучит как «ДжиДиПи́»
        </div>
      </div>

    </div>
    <div class="page__min-container page__content-block">
      <div class="page__one-block">
        <CommunicationDialog v-bind:dialog="dialogAudio"/>
      </div>
    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/style">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Фирменный стиль
      </router-link>
      <router-link class="next-page__link" to="/identity/photo-style">
        Фотостиль
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/photo-style">
        Фотостиль
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";

.page {
  margin-bottom: 192px;

  .page__min-container {
    .page__text-block {
      .text__head {
        font-weight: 500;
      }
    }

    &:first-child {
      margin-bottom: 28px;

    }

    &:nth-child(2) {
      margin-bottom: 97px;

      .page__two-block {
        .communication-dialog {
          width: calc(100%);

          .communication-dialog__top {
            width: calc(100% - 10px);

            .communication-dialog__right-icon-warp {
              position: relative;
              top: -3px;
            }
          }
        }
      }
    }

    &:nth-child(3) {
      margin-bottom: 28px;

      .page__text-block {
        .text__head {
          margin-bottom: 6.7px;
          letter-spacing: 0.24px;
        }

        .text__normal {
          margin-bottom: 12px;
        }

        .text__normal:last-child {
          margin-bottom: 22px;
        }
      }
    }

    &:nth-child(4) {
      margin-bottom: 96px;
    }

    &:nth-child(5) {
      margin-bottom: 28px;
    }

    &:nth-child(6) {
      margin-bottom: 97px;
    }

    &:nth-child(7) {
      margin-bottom: 24px;

      .page__text-block {
        .text__head {
          margin-bottom: 3px;
          letter-spacing: 0.3px;
        }

        .text__normal {
          font-size: 16px;
        }
      }
    }
  }

  .text__big {
    font-size: 20px;
    line-height: 32px;
  }

  .page__two-block {
    width: calc(50% - 16px);
  }

  .page__content-block {
    margin-bottom: 32px;
  }

  .text__head {
    margin-bottom: 8.3px;

    letter-spacing: 0.1px;
    line-height: 42.9px;
  }

  @media screen and (max-width: 1100px) {
    .page__two-block {
      width: 100%;
    }
  }
}
</style>
<script>
import CommunicationDialog from "@/components/CommunicationRules/CommunicationDialog";

export default {
  components: {CommunicationDialog},
  data() {
    return {
      dialogEvent: {
        correctly: true,
        icon: '/img/communication-page/icon-b.svg',
        text: 'GDP'
      },
      dialogBrand: {
        class: "communication-dialog__body_gdp",
        correctly: false,
        icon: '/img/communication-page/icon-b.svg',
        text: 'ГДП'
      },
      dialogJuristic: {
        correctly: true,
        icon: '/img/communication-page/icon-b.svg',
        text: 'Компания GDP активно работает в бюджетном сегменте. '
      },
      dialogCase: {
        class: "company-processed",
        correctly: true,
        icon: '/img/communication-page/icon-b.svg',
        // text: '2700 торгов обработано компанией GDP',
        textArr: [
          '2700 торгов',
          'обработано компанией GDP'
        ]
      },
      dialogCaseFalse: {
        class: "processed",
        correctly: false,
        icon: '/img/communication-page/icon-b.svg',
        textArr: [
          '2700 торгов',
          ' обработано GDP'
        ],
        // text: '2700 торгов обработано GDP'
      },
      dialogAudio: {
        correctly: true,
        audio: true,
        icon: '/img/communication-page/icon-b.svg',
        text: 'Препараты, поставленные компанией «ДжиДиПи́» в 2020 году.'
      }
    }

  }
}
</script>
