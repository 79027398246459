<template>
  <div class="communication-dialog" v-bind:class="{correctly: dialog.correctly }">
    <div class="communication-dialog__top">
      <div class="communication-dialog__left-icon-wrap">
        <!--        <img :src="dialog.icon"-->
        <!--             :alt="dialog.icon">-->

        <!--        #54e27c40  #ff4c4c33-->
        <svg v-if="!dialog.audio" width="55" height="42" viewBox="0 0 55 42" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g style="mix-blend-mode:multiply">
            <path
                d="M1.00884 0C0.451673 0 0 0.451672 0 1.00884V23.1387C0 23.6959 0.451673 24.1476 1.00884 24.1476H9.45463L0.447943 40.5046C0.275856 40.8171 0.281636 41.1973 0.463144 41.5044C0.644653 41.8116 0.974895 42 1.33167 42H12.4047C12.7365 42 13.0471 41.8369 13.2354 41.5637L24.7603 24.8411C24.8764 24.6728 24.9385 24.4731 24.9385 24.2686V1.00884C24.9385 0.451672 24.4868 0 23.9297 0H1.00884Z"
                :fill="dialog.correctly?'#F5F5F5':'#F5F5F5'"/>
            <path
                d="M31.0703 0C30.5132 0 30.0615 0.451672 30.0615 1.00884V23.1387C30.0615 23.6959 30.5132 24.1476 31.0703 24.1476H39.5161L30.5094 40.5046C30.3373 40.8171 30.3431 41.1973 30.5246 41.5044C30.7061 41.8116 31.0364 42 31.3932 42H42.4662C42.798 42 43.1086 41.8369 43.2968 41.5637L54.8218 24.8411C54.9379 24.6728 55 24.4731 55 24.2686V1.00884C55 0.451672 54.5483 0 53.9912 0H31.0703Z"
                :fill="dialog.correctly?'#F5F5F5':'#F5F5F5'"/>
          </g>
        </svg>
        <svg v-if="dialog.audio" width="30" height="46" viewBox="0 0 30 46" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M30 1.10475C30 0.168444 28.8983 -0.34176 28.1752 0.259663L13.643 12.3461C13.443 12.5125 13.1903 12.6037 12.9293 12.6037H1.11111C0.497461 12.6037 0 13.0974 0 13.7063V32.2937C0 32.9026 0.497461 33.3963 1.11111 33.3963H12.9293C13.1903 33.3963 13.443 33.4875 13.643 33.6539L28.1752 45.7403C28.8983 46.3418 30 45.8316 30 44.8953V1.10475Z"
              :fill="dialog.correctly?'#DDE1E8':'#F5F5F5'"/>
        </svg>


      </div>
      <div class="communication-dialog__right-icon-warp">


        <img src="~assets/img/icons/icon-currently.svg"
             alt="currently"
             v-if="dialog.correctly">
        <img src="~assets/img/icons/icon-not-currently.svg"
             alt="not-currently"
             v-if="!dialog.correctly">
      </div>
    </div>
    <div class="communication-dialog__body" v-if="dialog.text" :class="dialog.class">
      {{ dialog.text }}
    </div>
    <div class="communication-dialog__body-arr" v-if="dialog.textArr">
      <div class="communication-dialog__body-item"
           v-for=" (item, i) of dialog.textArr"
           :key="i"
      :class="dialog.class">
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['dialog']
}
</script>
<style lang="scss" scoped>
.communication-dialog {
  margin-top: 20px;
  padding: 12px;
  overflow: hidden;
  min-height: 128px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #E6EAF2;

  &__top {
    position: absolute;
    top: 8px;
    left: 12px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 16px)
  }

  &__body {
    //max-width: 329px;
    color: #242B33;
    font-size: 20px;
    line-height: 32px;
    position: absolute;
    left: 70px;
    text-align: center;
    max-width: calc(100% - 130px);
    min-width: calc(100% - 126px);
    width: calc(100% - 130px);

    &:nth-child(3) {
      left: 50px;
    }

    &-arr {
      color: #242B33;
      font-size: 20px;
      line-height: 32px;
      position: absolute;
      left: 63px;
      display: flex;
      max-width: calc(100% - 130px);
      min-width: calc(100% - 116px);
      flex-wrap: wrap;
      top: 32px;
      flex-direction: column;
      align-items: center;

    }

    &-item {
      width: 100%;
      text-align: center;
      min-width: 96px;
    }
  }
}

.communication-dialog__body_gdp {
  margin-left: -7px;
}
.company-processed {
  &:nth-child(2) {
    margin-left: 2px;

  }
}
.processed {
  margin-left: -15px;
  &:last-child {
    margin-left: -10px;
  }
}
.communication-dialog__body-gpd {
  left: 30px;
}

@media screen and (max-width: 1100px) {
  .communication-dialog__body-item {
    width: calc(50% - 8px);
  }
  .communication-dialog__body,
  .communication-dialog__body-arr {
    position: relative;
    left: 17px;
    padding-bottom: 30px;
    top: 20px;
    max-width: calc(100% - 130px);
    min-width: calc(100% - 126px);
  }
}
</style>
